<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" persistent width="700" @click:outside="$emit('close')">
    <v-card tile class="pt-2">
      <v-card-title class="mb-4">
        <span class="headline"><v-icon class="mb-1 mr-1">mdi-server</v-icon> Nova Conexão</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="server.database_name" :rules="dbRules" label="Database*" outlined required />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="server.host" class="mr-2" :rules="hostRules" label="Host*" outlined required />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="server.port" class="ml-2" :rules="portRules" type="number" label="Porta*" outlined required />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="server.user" class="mr-2" :rules="userRules" label="Usuário*" outlined required />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="server.password"
                class="ml-2"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                :rules="passRules"
                name="input-10-1"
                label="Senha"
                counter
                autocomplete="off"
                outlined
                required
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-row no-gutters class="pb-4">
        <btn-save-cancel :save-btn-disable="!valid" :btn-size="180" props-class="ml-4 pt-0" @onSave="saveServer" @onCancel="$emit('close')" />
        <v-btn width="180" class="mb-0" color="primary" outlined @click="clearFields"> <v-icon class="mr-1">sync</v-icon> Limpar </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    BtnSaveCancel: () => import('@/components/base/BtnSaveCancel')
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    server: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    show: false,
    valid: false,
    integratorRules: [v => !!v || 'Integrator é obrigatório'],
    dbRules: [v => !!v || 'Database é obrigatório'],
    charsetRules: [v => !!v || 'Charset é obrigatório'],
    hostRules: [v => !!v || 'Host é obrigatório'],
    portRules: [v => !!v || 'Porta é obrigatória'],
    userRules: [v => !!v || 'User é obrigatório'],
    passRules: [v => !!v || 'Password é obrigatório']
  }),
  methods: {
    saveServer() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })
      this.$emit('update:server', this.server)
      this.$emit('save')
    },
    clearFields() {
      this.server.host = ''
      this.server.port = ''
      this.server.user = ''
      this.server.password = ''
      this.server.database_name = ''
    }
  }
}
</script>
